// https://denv3.highmarkbcbs.com/login/#/coverage/medical-benefits
module.exports = function (app) {

    var myModal = require("../../modals/benefitBookletError");

    app.controller('pages.coverage.medicalBenefits', ['$scope', 'session', 'services', 'finder-logic2', '$location', 'coremetrics', "modal", "$cookies", "invisibleFormService", "teamsite", "brand", "sessionData", "ssoHelper","preventiveScheduleService", "cceService","requestAPrintedCopyService",
        function ($scope, session, services, finderLogic, $location, coremetrics, modal, $cookies, invisibleFormService, teamsite, brand, sessionData, ssoHelper, preventiveScheduleService, cceService,requestAPrintedCopyService) {
            window.$scope = $scope;
            var bXhrUmsDone = false;
            var bXhrCsbDone = false;
            var bXhrWtokDone = false;
            var bXhrTsDone = false;
            var bXhrSbcDone = false;

            $scope.isMincr = brand.current.sharedBrandCode.toLowerCase() == 'mincr';
            window.document.title = "Medical Benefits";

            // This is the common pattern to activate the Sapphire code ... current brand has to support, as well
            // as the appConfig flag.
            $scope.isVitalsLiveCCE = (sessionData && sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE_CCE) ? sessionData.appConfig.PROVIDER.VITALS_LIVE_CCE.booleanValue : false;

            // teamsite.get('MBR_COVERAGE_MEDICALBEN_NETWORKANDREFERRAL_BLUECOVERAGE_LINKURL').then(function (response) {
            //     $scope.blueCoverageLinkUrl = response;
            // });

            // Set up Sapphire Digital Modal
            $scope.showVitalsModal = function(){
                var targUrl = finderLogic.getSapphireSSOUrl(brand.current.sharedBrandCode);
                if (isNDBCBS || isWYBCBS || isMINCR) {
                    window.open(targUrl);
                } else {
                    $scope.continueToDestination = function(){
                        window.open(targUrl);
                        $scope.closeModal();
                    };
                    $scope.siteToLeave = window.location.host.indexOf("mybenefitshome.com") !== -1 ?"Your health plan": "Highmark";
                    modal.open({
                        heading: "YOU WILL BE REDIRECTED TO SAPPHIRE DIGITAL",
                        template: require('modals/vitalsDisclaimerModal.html'),
                        scope:$scope
                    });
                }
            };

            $scope.homeHostSuppression = false;
            if(brand.current.sharedBrandCode !== "mincr" ||brand.current.sharedBrandCode !== "wybcbs" || brand.current.sharedBrandCode !== "ndbcbs") {
                var homeHostClientNumber = [];
                teamsite.get("HOME_HOST_CLIENT_NUMBERS").then(function(content) {
                    homeHostClientNumber = JSON.parse(content);
                    var isHomeHostClientIndex = (homeHostClientNumber.indexOf($cookies.get("mbrClientNumber")) > -1);
                    if(isHomeHostClientIndex){
                        var portalSelectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();
                        for(var i = 0; i < portalSelectedPlan.optOutList.length; i++) {
                            var optOutListInfo = portalSelectedPlan.optOutList[i];
                            if(optOutListInfo.code === "904"){
                                $scope.homeHostSuppression = true;
                            }
                        }
                    }
                });
            }

            $scope.getPrintedCopy = function() {
                requestAPrintedCopyService.requestCopy().then(function(response){
                    var responsePrintedService = response;
                    console.log("response printed service",responsePrintedService );
                    if (responsePrintedService === 'success') {
                        modal.open({
                            template: require('modals/requestPrintedDrugCoverageModal.html')
                        });
                    } else {
                        modal.open({
                            template: require('modals/requestPrintedDrugCoverageErrorModal.html')
                        })
                    }
                });
            };



            $scope.chcClick = function () {
                ssoHelper.chcSsoCall();
            };

            $scope.xhrRequestFinished = function (xhrName) {
                if (xhrName == 'UMS') bXhrUmsDone = true;
                if (xhrName == 'CSB') bXhrCsbDone = true;
                if (xhrName == 'WTOK') bXhrWtokDone = true;
                if (xhrName == 'TS') bXhrTsDone = true;
                if (xhrName == 'SBC') bXhrSbcDone = true;


                if (bXhrUmsDone && bXhrCsbDone && bXhrWtokDone && bXhrTsDone && bXhrSbcDone) {
                    if ($location.search().scroll) {
                        var element = document.getElementById($location.search().scroll);
                        if (element)
                            element.scrollIntoView(true);
                    }
                }
            };

            $scope.getPSPDF = function(){
                preventiveScheduleService.getPreventiveSchedulePDF().then(function(response){
                    $scope.preventiveURL=response.data.payload.url;
                });
            };

            $scope.isPlanCancelled = function (currentProd) {
                var bRetVal = false;
                var nMemberIdx = 0;
                var mbr = currentProd.memberList[nMemberIdx];
                if (mbr.coverageCancellationDate != '')
                    bRetVal = ((new Date(mbr.coverageCancellationDate)) < (new Date()));

                return bRetVal;
            };


            // Coremetrics tagging
            $scope.Coremetrics = function (element) {
                coremetrics.elementTag(element, 'MEMBER:YOUR COVERAGE:MEDICAL DETAILS');
            };

            $scope.cmPreAuthList = function () {
                coremetrics.elementTag('Prior Authorization Procedures', 'MEMBER: YOUR COVERAGE');
            };
            $scope.cmDEMedPolicy = function () {
                coremetrics.elementTag('Medical Policy', 'MEMBER: YOUR COVERAGE');
            };


            var hasCoverage = function (curGrp, strPlan) {
                var bFound = false;
                for (iPrdIdx = 0; iPrdIdx < curGrp.productList.length; ++iPrdIdx) {
                    var curPrd = curGrp.productList[iPrdIdx];
                    if (curPrd.benefitPlanType === strPlan) {
                        bFound = true;
                        break;
                    }
                }
                return bFound;
            };

            var isHmo = function (theProducts) {
                var lobIsHmo = false;
                var hasHmo = false;
                var coverageIsCurrent = false;
                for (var i = 0; i < theProducts.length; i++) {
                    var p = theProducts[i];
                    lobIsHmo = ((p.lob == 'HMO') && (true)) ? true : lobIsHmo;
                    var milliServerDate = Date.parse(this.model.serverDate);
                    coverageIsCurrent = (Date.parse(p.coverageEndDate) > milliServerDate) ? true : coverageIsCurrent; // 12/31/2014
                }
                hasHmo = lobIsHmo && coverageIsCurrent;
                return hasHmo;
            };

            cceService.shouldDisplay().then( function(hasCCE) {
                $scope.bShowCompareCosts = hasCCE;
            }, function(error) {
                $scope.bShowCompareCosts = false;
            });

            var isMINCR = (brand.current.sharedBrandCode === 'mincr'),
                isNDBCBS = (brand.current.sharedBrandCode === 'ndbcbs'),
                isWYBCBS = (brand.current.sharedBrandCode === 'wybcbs'),
                isDe = finderLogic.getPropertyByBrandCode(brand.current.sharedBrandCode, 'regionIsDe'),
                isMedicare = finderLogic.hasSeniorProduct() || finderLogic.isOver65();

            $scope.bIsMINCR = isMINCR;
            $scope.bIsNDBCBS = isNDBCBS;
            $scope.bIsWYBCBS = isWYBCBS;

            $scope.COVERAGE_HIDE_DEFAULT_HERO = false;
            if(isMINCR || isWYBCBS) {
                $scope.COVERAGE_HIDE_DEFAULT_HERO = true;
            }
            $scope.hideImages = isMINCR;
            $scope.whatYouPayClass = (!isMINCR ? "pullUpwards" : "");
            $scope.showPriorAuth = !isMINCR;
            $scope.showDEMedPolicy = isDe;
            $scope.isMedicare = isMedicare;

            services.rbsmbl.memberInfo().fetch().then(function (fullMemberInfo) {

                finderLogic.setFullMemberInfo(fullMemberInfo.data.payload);
                var theDomain = finderLogic.theDomain();
                var ums = fullMemberInfo.data.payload;
                var nClntIdx = 0;
                var curClnt = ums.clientList[0];
                var curGrp = finderLogic.getPortalSelectedPlan(fullMemberInfo);
                var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                $scope.bMedicalExists = false;

                $scope.umi = ums.umi;
                $scope.clientNumber = (curClnt && curClnt.clientNumber) ? curClnt.clientNumber : "0";
                $scope.groupNumber = (curClnt.groupList[0] && curClnt.groupList[0].groupNumber) ? curClnt.groupList[0].groupNumber : "0";

                $scope.showPreventiveLink = true;
                if (isDe && curClnt.clientName === 'DUPONT') {
                    $scope.showPreventiveLink = false;
                }
                if (($cookies.get('mbrGroupNumber') || '') != '') {
                    for (iClntIdx = 0; iClntIdx < ums.clientList.length; ++iClntIdx) {
                        var tmpClnt = ums.clientList[iClntIdx];
                        for (iGrpIdx = 0; iGrpIdx < tmpClnt.groupList.length; ++iGrpIdx) {
                            if (tmpClnt.groupList[iGrpIdx].groupNumber == $cookies.get('mbrGroupNumber')) {
                                curClnt = tmpClnt;
                                curGrp = tmpClnt.groupList[iGrpIdx];
                                for (iPrdIdx = 0; iPrdIdx < curGrp.productList.length; ++iPrdIdx) {
                                    var tmpPrd = curGrp.productList[iPrdIdx];
                                    if (tmpPrd.benefitPlanType === "Medical") {
                                        curPrd = tmpPrd;
                                        break;
                                    }

                                }
                            }
                        }
                    }
                }


                $scope.portalSelectedPlan = finderLogic.getPortalSelectedPlan();
                $scope.isAgOnlyMember = function () {
                    var ps = $scope.portalSelectedPlan.productList;
                    var isAgOnly = true;
                    for (var i = 0; i < ps.length; i++) {
                        isAgOnly = (ps[i].lob != 'AG') ? false : isAgOnly;
                    }
                    // console.log("isAgOnly",isAgOnly);
                    return isAgOnly;
                };

                $scope.bShowChc = ($scope.portalSelectedPlan && $scope.portalSelectedPlan.chcIndicator && $scope.portalSelectedPlan.chcIndicator == 'true') ? true : false;

                $scope.bAnyDiscountVisible = !isMINCR; // just a default.
                $scope.bbluelinkshow = true;

                // checking for WPA, CPA, DE, WV and MyBenefitsHome
                var isbbluelinkshowregions = ((ums.brand == "HMBCBS") || (ums.brand == "PBS") || (ums.brand == "HBS") || (ums.brand == "WV") || (ums.brand == 'DE') || (['hmbcbswv', 'ms', 'highmarkbcbswv', 'mbh:wv', 'hhic', 'az', 'fl', 'mbh:fl', 'exc', 'lou', 'neb', 'regbcbs', 'regbs', 'wi', 'hbs', 'la','ub'].indexOf($scope.brandData.sharedBrandCode) >= 0));
               // $scope.bbluelinkshow = (isbbluelinkshowregions) ? !$scope.isAgOnlyMember() : $scope.bbluelinkshow; Commented out for RF544040

                if (curPrd != null) {
                    $scope.bMedicalExists = true;



                    // var bbParams = {
                    //     brand: ums.brand
                    // };

                    //medigap check
                    var bMedigapbrand = ((ums.brand === "HMBCBS") || (ums.brand === "HMBCBSWV") || (ums.brand === "MS") || (ums.brand === "HBS") || (ums.brand === "PBS") || (ums.brand === "HMBCBSDE") || (ums.brand === "DE"));

                    var medigapFlag = curGrp.medigapIndicator;

                    $scope.medigapValue = !((medigapFlag == true || medigapFlag === "true") && bMedigapbrand == true);
                    $scope.NDMEDICARE = ums.brand === "NDBCBS" && (medigapFlag == true || medigapFlag === "true")

                    // bbParams.payload = {
                    //     "visionCoverage": hasCoverage(curGrp, "Vision"),
                    //     "icisClientId": curClnt.clientId,
                    //     "icisClientNumber": curClnt.clientNumber,
                    //     "groupNumber": curGrp.groupNumber,
                    //     "medicalCoverage": hasCoverage(curGrp, "Medical"),
                    //     "drugCoverage": hasCoverage(curGrp, "Drug"),
                    //     "coverageCancelDate": (curPrd.memberList[0].coverageCancellationDate != "" ? curPrd.memberList[0].coverageCancellationDate : "00/00/0000"),
                    //     "curEffDate": (curPrd.coverageBeginDate != "" ? curPrd.coverageBeginDate : "00/00/0000"), // thePlan.coverageBeginDate,
                    //     "dentalCoverage": hasCoverage(curGrp, "Dental")
                    // };

                    // BENEFIT BOOKLET LINKS
                    var fileName;
                    var docKey;
                    var bShowANOC;
                    var futureBBDeferred;
                    var coverageCheckDeferred;
                    var groupNumber = curGrp.groupNumber;
                    var superProdNum = curPrd.superProductNumber;

                    var shouldShowBenefitBookletByDefault = function () {
                        if (sessionData.appConfig &&
                            sessionData.appConfig.COVERAGE &&
                            sessionData.appConfig.COVERAGE.SUPPRESS_FAILED_BENEFIT_BOOKLET) {
                            return !sessionData.appConfig.COVERAGE.SUPPRESS_FAILED_BENEFIT_BOOKLET.booleanValue;
                        }
                        return true;
                    };
                    $scope.showBenefitBookletLink = false;
                    $scope.hasMedicalBenefitDetails = function () {
                        return $scope.showBenefitBookletLink || $scope.bShowSbcLink;
                    };
                    services.rbsmbl.BenefitBooklet().getKey(groupNumber,superProdNum).then(
                        function (response) {
                            var key = response && response.data && response.data.documentKey;
                            var foundKey = key && !(key == undefined || key == '');
                            docKey = response && response.data;
                           
                            //fileName = bbResponse && bbResponse.url;
                            //$scope.linkName = bbResponse && bbResponse.linkName;
                            //$scope.bShowANOC = bbResponse && bbResponse.showHeaderLabel;

                            $scope.showBenefitBookletLink = foundKey;
                            if (!$scope.showBenefitBookletLink) {
                                $scope.showBenefitBookletLink = shouldShowBenefitBookletByDefault();
                                //$scope.linkName = (!$scope.linkName) ? 'Current Plan-Year' : $scope.linkName;
                            }
                            $scope.linkName = $scope.isMincr ? 'Benefit Booklet (PDF)': 'Current Plan-Year';
                            //$scope.acrobatFile = bbResponse && bbResponse.acrobatFile ? bbResponse.acrobatFile: false ;
                            //$scope.showMessage = $scope.isMincr && !$scope.acrobatFile ? true : false;

                        }, function (errorObj) {
                            $scope.showBenefitBookletLink = shouldShowBenefitBookletByDefault();
                            //$scope.linkName = (!$scope.linkName) ? 'Current Plan-Year' : $scope.linkName;
                            $scope.linkName = $scope.isMincr ? 'Benefit Booklet (PDF)': 'Current Plan-Year';
                            $scope.showErroMsg = $scope.isMincr ?  true : false;
                        }
                    );

                    $scope.bbClick = function () {
                        if($scope.showErroMsg) {
                            modal.open({
                                template: '<p>Sorry, we are having technical trouble. Please call the customer service number on the back of your ID card for help.</p>',
                                controller: myModal,
                                scope: $scope
                            });
                            return;
                        }
                        if (!docKey) {
                            modal.open({
                                template: require('modals/benefitBookletError.html'),
                                controller: myModal,
                                scope: $scope
                            });
                        // } else if(ums.brand === "HMBCBS" || ums.brand === "PBS"){
                        //     if (curClnt.groupList[0].onlineBenefitBooklet === "N"){
                        //         modal.open({
                        //             template: require("modals/benefitBookletDisclaimer.html"),
                        //             scope: $scope
                        //         });

                        //         $scope.openBenefitBooklet = function () {
                        //             window.open(fileName);
                        //             $scope.closeModal();
                        //         };
                        //     }
                        //     else{
                        //         window.open(fileName);
                        //         $scope.closeModal();
                        //     }
                        }
                        else{
                            modal.open(
                                {
                                    heading: 'Please Wait',
                                    template: '<p>File Download In Progress...</p>'
                                }
                            );
                            services.rbsmbl.BenefitBooklet().getFile(docKey).then(function (result){
                                var blob = new Blob([result.data], { type: "application/pdf" });
                                     var link = document.createElement('a');
                                     var evtClick = document.createEvent('MouseEvents');
                                     link.href = window.URL.createObjectURL(blob);
                                     var fileName = "Benefit-Booklet.pdf";
                                     link.download = fileName;
                                     evtClick.initEvent("click", true, true);
                                     link.dispatchEvent(evtClick);
                                     window.URL.revokeObjectURL(link.href);
                                     link.remove();
                                     modal.close();
                                     
                                
                                

                            },  function (error) { 
                                modal.open({
                                    heading: 'ERROR',
                                    template: '<p>Sorry, we are having technical trouble. Please call the customer service number on the back of your ID card for help.</p>',
                                    controller: myModal,
                                    scope: $scope
                                });
                                
                                return;
                              
                            });
                            
                        }
                       
                    };
                    // Request Printed Copy
                    // checks if current user has medicareAdvantageflag indicator set to true
                    var isMedicareAdv = sessionData.memberInfo.clientList[0].groupList[0].medicareAdvantageFlag;

                        if(isMedicareAdv){
                            $scope.showEOCContentGroup = true;
                        }


                        // if current day is beyond 8/31/2018 show content
                        var appConfigRequestCopy = (sessionData.appConfig &&
                            sessionData.appConfig.PRESCRIPTIONS &&
                            sessionData.appConfig.PRESCRIPTIONS.SHOW_EOC_REQUEST_COPY &&
                            sessionData.appConfig.PRESCRIPTIONS.SHOW_EOC_REQUEST_COPY.booleanValue) ? true : false;

                        if(appConfigRequestCopy === true){
                            $scope.showEOCContentTime  = true;
                        }


                        if($scope.showEOCContentGroup === true && $scope.showEOCContentTime === true) {
                            $scope.showEOCHeader = false;
                        } else {
                            $scope.showEOCHeader = true;
                        }
                    // });




                    // Future effective benefit booklet code

                    // var futureFilename;
                    // if(bbParams.payload.curEffDate !== "00/00/0000") {
                    //     bbParams.payload.curEffDate = bbParams.payload.curEffDate.substring(0, 6) + (parseInt(bbParams.payload.curEffDate.substring(6, 10)) + 1);

                    //     coverageCheckDeferred = services.rbsmbl.CoverageCheck().getData(curGrp.groupNumber, bbParams.payload.curEffDate.substring(6,10) + "-" + bbParams.payload.curEffDate.substring(0,2) + "-" + bbParams.payload.curEffDate.substring(3,5));
                    //     coverageCheckDeferred.then(function(data) {
                    //         futureBBDeferred = services.BenefitBooklet.fetch(bbParams);
                    //         futureBBDeferred.then(function (data) {
                    //                 bbResponse = data && data.data && data.data.payload;
                    //                 futureFilename = bbResponse && bbResponse.url;
                    //                 $scope.futureLinkName = "Next Plan-Year";

                    //                 $scope.showHeaderLabel = $scope.showHeaderLabel || bbResponse.showHeaderLabel;
                    //                 bbDeferred.then(function (data) {
                    //                     $scope.showFutureBBLink = !!futureFilename && !(futureFilename === fileName);
                    //                     //$scope.$apply();
                    //                 });
                    //                 //$scope.$apply();
                    //             },
                    //             function (errorObj){
                    //                 $scope.showFutureBBLink = false;
                    //                 //$scope.$apply();
                    //             });
                    //     });
                    // }

                    // $scope.futureBBClick = function() {
                    //     var brand = ums.brand || brand.current.sharedBrandCode;
                    //     coremetrics.elementTag('Download Benefit Booklet','MEMBER: YOUR COVERAGE');

                    //     if (!futureFilename) {
                    //         modal.open({
                    //             template: require('modals/benefitBookletError.html'),
                    //             controller: myModal,
                    //             scope: $scope
                    //         });
                    //     }
                    //     else{
                    //         if(ums.brand === "HMBCBS" || ums.brand === "PBS"){
                    //             modal.open({
                    //                 template: require("modals/benefitBookletDisclaimer.html"),
                    //                 scope: $scope
                    //             });

                    //             $scope.openBenefitBooklet = function () {
                    //                 window.open(futureFilename);
                    //                 $scope.closeModal();
                    //             };

                    //         }
                    //         else{
                    //             window.open(futureFilename);
                    //             $scope.closeModal();
                    //         }

                    //     }

                    // };



                    $scope.strPlanName = ((curGrp.hiosName || '') != '' ? curGrp.qhpLevel + " - " + curGrp.hiosName : finderLogic.portalSelectedPlanSpecificProduct("Medical").benefitPlanName);
                    var csbParams = {
                        brand: ums.brand
                    };
                    csbParams.payload = {
                        // TODO : Set Proper values here from curGrp and curPrd
                        "startDate": curPrd.coverageBeginDate.split("/").join(""), // "05012015", //TheDate.formatDate(curPrd.coverageBeginDate, "MMddyyyy") , // "05012015",  // curPrd.coverageBeginDate,  // 05/01/2014
                        "endDate": curPrd.coverageEndDate.split("/").join(""), // "04302016", //TheDate.formatDate(curPrd.coverageEndDate, "MMddyyyy") , // "04302016",   // curPrd.coverageEndDate   // 04/30/2015
                        "groupNum": curGrp.groupNumber,
                        "lob": "M",
                        "productId": curPrd.productNumber,
                        "memberPin": curPrd.memberList[0].pinNumber
                    };

                    services.rbsmbl.costShareBenefit().fetch(csbParams).then(function (csbResp) {
                        var arrNames = [];
                        var arrIgnoredRollupNetworkNames = ["Product Wide", "Product Wide ", "In and Out of Network Combined"];
                        var arrIgnoredProgramNames = ["OOP", "PDD"];

                        if (csbResp.data.payload.coverageDetails) {
                            csbResp.data.payload.coverageDetails.forEach(function (curCSB, idx) {
                                if ((arrIgnoredRollupNetworkNames.indexOf(curCSB.rollupNetworkName) == -1) && (arrIgnoredProgramNames.indexOf(curCSB.programName) == -1)) {
                                    var strName = curCSB.rollupNetworkName.replace(' ', '').replace(' ', '').replace('-', '').replace('-', '').toLowerCase();
                                    if (arrNames.indexOf(strName) == -1) {
                                        arrNames.push(strName);
                                    }
                                }
                            });
                        }

                        $scope.bShowEPOLine = ((curGrp.atLeastOneEpoExistsIndicator == "true") || (curPrd.lob == "HMO" && curPrd.alphaPrefix != "JOL" && curPrd.alphaPrefix != "JOF") || (curPrd.lob == "PDG"));
                        $scope.bShowForHMOPOS = (curPrd.lob == "HMO" && (curPrd.alphaPrefix == "JOL" || curPrd.alphaPrefix == "JOF"));


                        $scope.bOutOfNetwork = (arrNames.indexOf('outofnetwork') >= 0);
                        var nInNetwork = arrNames.length - ($scope.bOutOfNetwork ? 1 : 0);
                        $scope.b0InNetwork = (nInNetwork == 0);
                        $scope.b1InNetwork = (nInNetwork == 1);
                        $scope.b2InNetwork = (nInNetwork == 2);
                        $scope.b3InNetwork = (nInNetwork == 3);
                        $scope.nNumTiers = 0;


                        $scope.bIsHomeHost = (csbResp.data.payload.coverageSummary.isHomeHost === true) || (csbResp.data.payload.coverageSummary.isHomeHost === 'true');
                        $scope.bIsHomeHostPA = $scope.bIsHomeHost && ((ums.brand == 'HMBCBS') || (ums.brand == 'PBS'));
                        $scope.bIsHomeHostMS = $scope.bIsHomeHost && (ums.brand == 'MS');
                        $scope.bIsHomeHostDE = $scope.bIsHomeHost && (ums.brand == 'DE');
                        $scope.bIsHomeHostMiscellaneous = $scope.bIsHomeHost && ((ums.brand !== 'HMBCBS') && (ums.brand !== 'PBS') && (ums.brand !== 'MS') && (ums.brand !== 'DE'));


                        if (csbResp.data.payload.coverageSummary)
                            $scope.nNumTiers = csbResp.data.payload.coverageSummary.numberOfTiers;

                        $scope.bMultiTier = ($scope.nNumTiers > 1);
                        $scope.bShowCSG = (((curPrd.lob == "PPO") || (curPrd.lob == "POS") || (curPrd.lob == "HMO") || (curGrp.atLeastOneEpoExistsIndicator == "true")) && ((user.brand != "IBC") && (user.brand != "IBCCR") && (user.brand != "IBCAH")));
                        $scope.showMNInNetwork = $scope.showMNOutOfNetwork = isMINCR;
                        if (ums.brand === 'MINCR') { // Ensure that dynamic content for coverages is not displayed for BCBSMN
                            $scope.bOutOfNetwork = $scope.bShowEPOLine = $scope.b0InNetwork = $scope.b1InNetwork = $scope.b2InNetwork = $scope.b3InNetwork = false;
                            $scope.bShowCSG = true;
                        }
                        $scope.xhrRequestFinished('CSB');
                    }, function (errorObj) {
                        $scope.bShowCSG = false;
                        $scope.xhrRequestFinished('CSB');
                    });


                    $scope.bIsIBC_AH = ((ums.brand == 'IBCAH') || (ums.brand == 'IBCCR') || $scope.NDMEDICARE) ;
                    $scope.bShowTopQualSpecCare = true;
                    $scope.bShowCompareCosts = true;
                    $scope.bShowAdditionalProducts = true;

                    var nNumDental = 0,
                        nNumVision = 0,
                        nNumRx = 0,
                        nNumMedical = 0,
                        nNumSA = 0,
                        nNumWP = 0,
                        strBrand = ums.brand,
                        nNumProducts, bDirectPayIndicator = '',
                        nNumDP = 0;
                    var nIntTrueDental = 0,
                        nIntTrueVision = 0,
                        nIntTrueRx = 0,
                        nIntTrueMedical = 0,
                        strBrand = ums.brand,
                        nNumProducts;
                    var nIntFalseDental = 0,
                        nIntFalseVision = 0,
                        nIntFalseRx = 0,
                        nIntFalseMedical = 0,
                        strBrand = ums.brand,
                        nNumProducts;
                    var nNumRxClaimsTrue = 0,
                        nNumRxClaimsFalse = 0;
                    var nBEBTrue = 0,
                        nBEBFalse = 0,
                        nBEPTrue = 0,
                        nBEPFalse = 0;
                    nBlueDentalTrue = 0;
                    nBlueVisionTrue = 0;
                    var nHcrProdEmpty = 0,
                        nHcrProdNotEmpty = 0;
                    var nHMO = 0,
                        nHMOPOS= 0,
                        nPOS = 0,
                        nPPO = 0,
                        nEPO = 0;
                    var bIsSubscriber = false; // init to false
                    var bEpoExistsIndicator = false;

                    var bNoMedicalExits = false;
                    var nSenior = 0;

                    $scope.isUB = (ums.brand == 'UB');
                    for (iClntIdx = 0; iClntIdx < ums.clientList.length; ++iClntIdx) {
                        var tmpClnt = ums.clientList[iClntIdx];
                        for (iGrpIdx = 0; iGrpIdx < tmpClnt.groupList.length; ++iGrpIdx) {
                            var tmpGrp = tmpClnt.groupList[iGrpIdx];
                            if (tmpGrp.groupNumber == $cookies.get('mbrGroupNumber')) {
                                $scope.bShowTopQualSpecCare = ((tmpGrp.bdcIndicator == "true") || (tmpGrp.bdcPlusIndicator == "true"));
                                $scope.bShowCompareCosts = (tmpGrp.hasCareCostEstimator == "true");

                                $scope.bShowSaveWithHSA = (((tmpClnt.eligibleForHSAIndicator == "true") || (tmpGrp.hsaExistsIndicator == "true")) && ((ums.brand != "IBCCR") || (ums.brand != "IBCAH") || (ums.brand != "MINCR")));
                                $scope.bShowAdditionalProducts = ((ums.brand == 'HMBCBS') || (ums.brand == 'PBS') || (ums.brand == 'WV') || (ums.brand == 'DE'));

                                nNumProducts = tmpGrp.productList.length;
                                bDirectPayIndicator = tmpGrp.directPayIndicator;
                                var bdcIndicatorVal = tmpGrp.bdcIndicator;
                                var bdcPlusIndicatorVal = tmpGrp.bdcPlusIndicator;
                                var bdtcIndicatorVal = tmpGrp.bdtcIndicator;
                                var bVisionCancelled = false,
                                    bRxCancelled = false;
                                var bEpoExistsIndicator = (tmpGrp.atLeastOneEpoExistsIndicator == 'true');


                                $scope.bMedicare = (tmpGrp.medicareIndicator == "true");
                                $scope.bShowBlogLink = ((ums.brand == 'HMBCBS') || (ums.brand == 'PBS') || (ums.brand == 'WV') || (ums.brand == 'MS') || (ums.brand == 'DE'));


                                if ((tmpGrp.productList.length > 0) && (tmpGrp.productList[0].memberList.length > 0))
                                    bIsSubscriber = (tmpGrp.productList[0].memberList[0].relationship == "Subscriber"); //confirmed with Bryan

                                for (iPrdIdx = 0; iPrdIdx < tmpGrp.productList.length; ++iPrdIdx) {
                                    var tmpPrd = tmpGrp.productList[iPrdIdx];

                                    if (tmpPrd.benefitPlanType === "Medical") nNumMedical++;
                                    if (tmpPrd.benefitPlanType === "Dental") nNumDental++;
                                    if (tmpPrd.benefitPlanType === "Vision") nNumVision++;
                                    if (tmpPrd.benefitPlanType === "Drug") nNumRx++;
                                    if (tmpPrd.benefitPlanType === "Wellness Programs") nNumWP++;
                                    if (tmpPrd.benefitPlanType === "Spending Accounts") nNumSA++;

                                    if ((tmpPrd.benefitPlanType === "Medical") && (tmpPrd.integratedCoverageIndicator == "true")) nIntTrueMedical++;
                                    if ((tmpPrd.benefitPlanType === "Medical") && (tmpPrd.integratedCoverageIndicator == "false")) nIntFalseMedical++;
                                    if ((tmpPrd.benefitPlanType === "Dental") && (tmpPrd.integratedCoverageIndicator == "true")) nIntTrueDental++;
                                    if ((tmpPrd.benefitPlanType === "Dental") && (tmpPrd.integratedCoverageIndicator == "false")) nIntFalseDental++;
                                    if ((tmpPrd.benefitPlanType === "Vision") && (tmpPrd.integratedCoverageIndicator == "true")) nIntTrueVision++;
                                    if ((tmpPrd.benefitPlanType === "Vision") && (tmpPrd.integratedCoverageIndicator == "false")) nIntFalseVision++;

                                    if ((tmpPrd.benefitPlanType === "Vision") && (tmpPrd.isBlueBrandedVision == "true")) nBlueVisionTrue++;
                                    if ((tmpPrd.benefitPlanType === "Drug") && (tmpPrd.integratedCoverageIndicator == "true")) nIntTrueRx++;
                                    if ((tmpPrd.benefitPlanType === "Drug") && (tmpPrd.integratedCoverageIndicator == "false")) nIntFalseRx++;


                                    if (tmpPrd.benefitPlanType === "Drug") bRxCancelled = $scope.isPlanCancelled(tmpPrd);
                                    if (tmpPrd.benefitPlanType === "Vision") bVisionCancelled = $scope.isPlanCancelled(tmpPrd);

                                    if (tmpPrd.blueEdgeDentalIndv === "true") nBlueDentalTrue++;
                                    if ((tmpPrd.benefitPlanType === "Medical") && (tmpPrd.memberList.length > 0))
                                        $scope.CCEUrl = (!$scope.isVitalsLiveCCE) ? "/cce/landing-page.html?gn=" + tmpGrp.groupNumber + "&ed=" + tmpPrd.memberList[0].coverageEffectiveDate.replace(/\//gi, '') : '/rbsmbl/x-services/public/vitals/sso/' + strBrand;


                                    if (tmpPrd.rxClaims === "true") nNumRxClaimsTrue++;
                                    if (tmpPrd.rxClaims === "false") nNumRxClaimsFalse++;

                                    if (tmpPrd.isBlueExtraBasic === "true") nBEBTrue++;
                                    if (tmpPrd.isBlueExtraBasic === "false") nBEBFalse++;

                                    if (tmpPrd.isBlueExtraPlus === "true") nBEPTrue++;
                                    if (tmpPrd.isBlueExtraPlus === "false") nBEPFalse++;

                                    if (tmpPrd.hcrProduct === "") nHcrProdEmpty++;
                                    if (tmpPrd.hcrProduct !== "") nHcrProdNotEmpty++;

                                    if (bDirectPayIndicator === "true") nNumDP++;

                                    if (tmpPrd.lob == "HMO") nHMO++;
                                    if ((tmpPrd.lob == "HMO") && (tmpPrd.alphaPrefix == "JOL" ||tmpPrd.alphaPrefix == "JOF" )) nHMOPOS++;
                                    if (tmpPrd.lob == "POS") nPOS++;
                                    if (tmpPrd.lob == "PPO") nPPO++;
                                    if (tmpPrd.lob == "EPO") nEPO++;


                                    //if(tmpPrd.seniorProductIndicator =="true")   nSenior++;

                                    if ((curGrp.bdcIndicator == "true") || (curGrp.bdcPlusIndicator == "true"))
                                        $scope.BDCUrl = "/bdc/index.html?group=" + curGrp.groupNumber + "&userName=" + ums.userName + "&targetSite=" + ums.brand;
                                    if (brand.current.sharedBrandCode === 'wybcbs')
                                        $scope.BDCUrl = "https://www.bcbs.com/about-us/capabilities-initiatives/blue-distinction";

                                }
                            }

                        }
                    }
                    var emktUserName = ums.userName;
                    $scope.hideMemberDiscounts = ((ums.brand == 'IBCAH') || ((nNumDP > 0) && (nBlueDentalTrue > 0)) || ((ums.brand == 'IBCCR') && (nBEBTrue > 0)) || ($scope.bMedicare));
                    $scope.bdtcIndicator = (bdtcIndicatorVal == "true");

                    $scope.bEPOIndicatorExists = bEpoExistsIndicator;
                    $scope.bPPOExists = (nPPO > 0);
                    $scope.bHMOExists = (nHMO > 0);
                    $scope.bHMOPOSExists = (nHMOPOS > 0);
                    $scope.bPOSExists = (nPOS > 0);
                    $scope.bEPOExists = (nEPO > 0);
                    $scope.bShowPPO = (($scope.bPPOExists) && (!$scope.bEPOIndicatorExists));

                } //end of chk for presence of medical product

                var rwdsQualifParam = {
                    "brand": ums.brand,
                    "memberQualifierInfo": {
                        "clientNumber": curClnt.clientNumber,
                        "groupNumber": curGrp.groupNumber,
                        "qualifierList": ["webMDRewardsIndicator"]
                    }
                };

                $scope.isSharecareMember = false;

                $scope.bShowRewards = false;
                services.rbsmbl.rewardsQualifier().fetch(rwdsQualifParam).then(function (rwdsQualifResp) {

                    if ((rwdsQualifResp != null) && (rwdsQualifResp.data != null) && (rwdsQualifResp.data.payload != null) && (rwdsQualifResp.data.payload.qualifierInfo != null)) {
                        var bRewardsIndicatorIsTrue = (rwdsQualifResp.data.payload.qualifierInfo.webMDRewardsIndicator == "true");
                        var membInCoreProgramGroup =
                            (($scope.brandData.brandVisibility.indexOf('hhic') > -1) ||
                                (['highmarkbcbs.com', 'highmarkbcbsde.com', 'highmarkblueshield.com', 'highmarkbcbswv.com'].indexOf($scope.brandData.domain) > -1) ||
                                (['hmbcbs', 'hbcbs', 'highmarkbcbs', 'hbs', 'pbs', 'hmbs', 'hmbcbswv', 'ms', 'highmarkbcbswv', 'mbh:wv', 'hhic', 'az', 'fl', 'mbh:fl', 'exc', 'lou', 'neb', 'regbcbs', 'regbs', 'wi', 'ibc', 'mn', 'inac', 'la','ub'].indexOf($scope.brandData.sharedBrandCode) > -1)
                            );
                        // $scope.brandData.sharedBrandCode or to use prismBrandCode ? ? ? (shared is inac, but in healthy-program.js, on prod, share is ibc

                        var bIsBEB = (nBEBTrue == 0);

                        // membInCoreProgramGroup = true ; // TODO: HACK, to test the remaining code.


                        teamsite.get("MBR_GLOBAL_WELLTOK_GROUPS").then(function (tsResp) {

                            $scope.bIsWelltokGroup = tsResp.split(';').indexOf(curGrp.groupNumber) > -1;
                            var bNotPreEff = true;

                            var bAnyDiscountVisible = true;
                            $scope.bShowRewards = (membInCoreProgramGroup &&
                                (bRewardsIndicatorIsTrue || $scope.bIsWelltokGroup)
                                && bIsBEB && bNotPreEff);
                            $scope.bAnyDiscountVisible = ($scope.bShowRewards || !$scope.hideMemberDiscounts) && (!isMINCR);


                            if ($scope.bShowRewards) {
                                // Now need to check if WellTokGroup or bRewardsIndicatorIsTrue. If both are true, prefering WellTokGroup
                                if ($scope.bIsWelltokGroup) {
                                    // Call WellTok Svc
                                    $scope.wellTokClick = function () {
                                        var wellTokParams = {
                                            "brand": ums.brand,
                                            "payload": {
                                                "ecid": curPrd.memberList[0].ecId,
                                                "umi": ums.umi,
                                                "ciid": curPrd.memberList[0].coveredIndividualId,
                                                "clientNumber": curClnt.clientNumber,
                                                "groupNumber": curGrp.groupNumber
                                            }
                                        };

                                        services.rbsmbl.WellTok().fetch(wellTokParams).then(function (wellTokResp) {

                                            var wellTokFormConfig = {
                                                "name": "ssoLogin",
                                                "id": "form-login-main",
                                                "formTarget": "wellTokWindow",
                                                "method": "POST",
                                                "action": wellTokResp.data.payload.redirectionURL,
                                                "target": "_blank",
                                                "inputs": {
                                                    'URLparms': wellTokResp.data.payload.URLparms,
                                                    "cookieData": wellTokResp.data.payload.cookieData,
                                                    "userName": wellTokResp.data.payload.userName,
                                                    "destinationURL": wellTokResp.data.payload.destinationURL,
                                                    "action-login-main": 'submit'
                                                }
                                            };

                                            invisibleFormService.submit(wellTokFormConfig);


                                        }, function (wellTokErr) {
                                            // Well Tok Error happened. // TODO: Show a modal dialog like could not get your file.
                                        });
                                    };
                                } else {
                                    // Show WebMD Link
                                    // Link is already set as a A href.... nothing more to do here.
                                }
                            }
                            $scope.xhrRequestFinished('TS');
                        }, function (tsError) {
                            $scope.xhrRequestFinished('TS');
                        });
                    }
                    $scope.xhrRequestFinished('WTOK');
                }, function (errorObj) {
                    $scope.xhrRequestFinished('WTOK');
                });


                if (curPrd != null) {
                    $scope.bMedicalExists = true;

                    $scope.toolTipContent = "To get a free paper copy,<br/> call the number on the back of your ID card.";
                    $scope.deductibletoolTipContent = "If your plan does <i>not</i> have a deductible, you pay for only part of your care right from the start.";
                    $scope.bShowSbcLink = false;

                    $scope.showMINCR = false;
                    if(isMINCR) {
                        $scope.showMINCR = true;
                    }
                    else{
                        $scope.showMINCR = false;
                    }

                    $scope.showCCS = false;

                    services.rbsmbl.vbbMemberInfo()
                        .getData()
                        .then(function(memberData) {
                            if(memberData && memberData.data && memberData.data.payload){
                                var memData = memberData.data.payload;
                                $scope.ccsFlag = false;
                                $scope.ccsEnhancedFlag = false;
                                if(memData.memberList[0].packages[0].packageCode == "CONDITION MNGT"){
                                    $scope.ccsFlag = true;
                                }
                                else if(memData.memberList[0].packages[0].packageCode == "PROTOCOL COMPL"){
                                    $scope.ccsEnhancedFlag = true;
                                }
                                else{
                                    $scope.showCCS = false;
                                }

                                if($scope.showMINCR && $scope.ccsFlag || $scope.ccsEnhancedFlag){
                                    $scope.showCCS = true;
                                }
                                else{
                                    $scope.showCCS = false;
                                }
                            }
                        });

                    $scope.bShowVBB = (teamsite.qualifiers['valueBasedBenefits'] == true);
                    var user = ums;

                   // var sbcParams = {
                    //     brand: ums.brand
                    // };
                    var checkSbcKey = function(){
                        var sbcParams = {
                            "productId": curPrd.superProductNumber, //productList[$scope.iPrdIdx].productNumber ,
                            //"isErisaIndicator": curGrp.erisaIndicator,
                            "groupNumber": curGrp.groupNumber,
                            "visionProductId" : "0000000000",
                            "dentalProductId" : "0000000000"
                            //"effectiveCoverageDate": curPrd.coverageBeginDate
                        };
                        if ($scope.bIsWYBCBS) {
                   
    
                            for (i = 0; i < curGrp.productList.length; ++i) {
                                var product =  curGrp.productList[i];
                                switch (product.benefitPlanType) {
                                    case "Medical":
                                        sbcParams.productId = curGrp.productList[i].superProductNumber;
                                        break;
                                    case "Dental":
                                        sbcParams.dentalProductId = curGrp.productList[i].superProductNumber;
                                        break;
                                    case "Vision":
                                        sbcParams.visionProductId = curGrp.productList[i].superProductNumber;
                                        break;
                                }
                            };
                            return services.rbsmbl.SummaryOfBenefits().getKey(sbcParams);
            
                        }else{
                            return services.rbsmbl.SummaryOfBenefits().getKey(sbcParams);
                        }

                    };

                    checkSbcKey().then(function(response){
                        var key = response && response.data && response.data.documentKey;
                        $scope.bShowSbcLink  = key && !(key == undefined || key == '');
                        var sbcKey = response && response.data;
                        $scope.sbcClick = function () {
                            
                                modal.open(
                                    {
                                        heading: 'Please Wait',
                                        template: '<p>Summary of Benefits file download in progress...</p>'
                                    }
                                );
                                services.rbsmbl.SummaryOfBenefits().getFile(sbcKey).then(function (result){
                                    var blob = new Blob([result.data], { type: "application/pdf" });
                                         var link = document.createElement('a');
                                         var evtClick = document.createEvent('MouseEvents');
                                         link.href = window.URL.createObjectURL(blob);
                                         var fileName = "Summary-of-Benefits.pdf";
                                         link.download = fileName;
                                         evtClick.initEvent("click", true, true);
                                         link.dispatchEvent(evtClick);
                                         window.URL.revokeObjectURL(link.href);
                                         link.remove();
                                         modal.close();
                                         
                                 },  function (error) { 
                                    modal.open({
                                        heading: 'ERROR',
                                        template: '<p>Sorry, we are having technical trouble. Please call the customer service number on the back of your ID card for help.</p>',
                                        controller: myModal,
                                        scope: $scope
                                    });
                                    
                                    return;
                                  
                                });
                                
                            
                        }
     

                    })


                    
                    // services.rbsmbl.SummaryOfBenefits().fetch(sbcParams).then(function (dataSbcSvc1) {
                    //     var bNotInFuture = true;

                    //     // if ((dataSbcSvc1.data.payload.url || '') != '') {
                    //     //     // CASE: IBC or special IBC
                    //     //     // checking for null on UI side
                    //     //     if (dataSbcSvc1.data.payload.url.length == 0) {
                    //     //         // url is an array of length 0, hide SBC link.
                    //     //         $scope.bShowSbcLink = false;
                    //     //     } else {
                    //     //         // url is an array of length > 0
                    //     //         // see if we have a URL array element for the current benefitPlanType
                    //     //         $scope.bShowSbcLink = false;

                    //     //         dataSbcSvc1.data.payload.url.forEach(function (curSbc, idx) {
                    //     //             if (curSbc.module == curPrd.benefitPlanType) {
                    //     //                 $scope.bShowSbcLink = true;
                    //     //                 //$scope.teamsiteContent = lang.clone($scope.teamsiteContent);
                    //     //                 //$scope.teamsiteContent.MER_COVERAGE_SUMMARY_SBC = lang.clone(curSbc.label);

                    //     //                 if ((curSbc.path || '') == '') {
                    //     //                     // WE do not have a path. Need to do the form submit thing.
                    //     //                     var objIbc2Param = {
                    //     //                         "groupNumber": curGrp.groupNumber,
                    //     //                         "effectiveCoverageDate": curPrd.coverageBeginDate
                    //     //                     };
                    //     //                     console.log(objIbc2Param);
                    //     //                     console.log(JSON.stringify(objIbc2Param));

                    //     //                     var GetSbcFormConfig = {
                    //     //                         "name": "GetSbcDocForm",
                    //     //                         "id": "GetSbcDocFormId",
                    //     //                         "formTarget": "GetSbcDocSvcsWindow",
                    //     //                         "method": "POST",
                    //     //                         "action": "/rbsmbl/x-services/sbcAllGroups/file",
                    //     //                         "target": "_blank",
                    //     //                         "inputs": {
                    //     //                             "payload": JSON.stringify(objIbc2Param)
                    //     //                         }

                    //     //                     };

                    //     //                     $scope.hrefSbcUrl = "";
                    //     //                     $scope.sbcClick = function () {
                    //     //                         invisibleFormService.submit(GetSbcFormConfig);
                    //     //                         //btsGlobal.coremetrics.elementTag('Download Summary of Benefits','MEMBER: YOUR COVERAGE');

                    //     //                     };
                    //     //                 } else {
                    //     //                     // just add a A-Href with path as the href
                    //     //                     $scope.sbcClick = function () {
                    //     //                         window.open(curSbc.path); //+'?tgBPT='+curSbc.module
                    //     //                         //btsGlobal.coremetrics.elementTag('Download Summary of Benefits','MEMBER: YOUR COVERAGE');
                    //     //                     };
                    //     //                 }
                    //     //             }

                    //     //         });
                    //     //     }
                    //     // } else {
                    //     //     // NoURL ==> HighMark user

                    //     //     if ((curPrd.memberList.length > 0) && (curPrd.memberList[0].coverageEffectiveDate != '') && (new Date(curPrd.memberList[0].coverageEffectiveDate)) > (new Date()))
                    //     //         bNotInFuture = false;

                    //     //     //future effective rule only ffor non IBC members -confirmed with Fomeeka and Doug.C
                    //     //     if ((bNotInFuture)) {

                    //     //         if (((dataSbcSvc1.data.payload.marketSegment || '') != '') && ((dataSbcSvc1.data.payload.fileName || '') != '') && (dataSbcSvc1.data.payload.size != '')) {

                    //     //             if ((dataSbcSvc1.data.payload.marketSegment == 'Group') && ((dataSbcSvc1.data.payload.docId || '') == '')) {
                    //     //                 $scope.bShowSbcLink = false;
                    //     //             } else {
                    //     //                 // Only medical can have SBC for HM user
                    //     //                 $scope.bShowSbcLink = (curPrd.benefitPlanType == "Medical"); //true;

                    //     //                 var objSbc2Param = {
                    //     //                     "marketSegment": dataSbcSvc1.data.payload.marketSegment, // "Group",
                    //     //                     "docId": dataSbcSvc1.data.payload.docId || '',
                    //     //                     "fileName": dataSbcSvc1.data.payload.fileName //"GE_10000015_20140101_SBC"

                    //     //                 };

                    //     //                 var GetSbcFormConfig = {
                    //     //                     "name": "GetSbcDocForm",
                    //     //                     "id": "GetSbcDocFormId",
                    //     //                     "formTarget": "GetSbcDocSvcsWindow",
                    //     //                     "method": "POST",
                    //     //                     "action": "/rbsmbl/x-services/sbcAllGroups/file",
                    //     //                     "inputs": {
                    //     //                         "payload": JSON.stringify(objSbc2Param)
                    //     //                     }

                    //     //                 };

                    //     //                 $scope.hrefSbcUrl = "";
                    //     //                 $scope.sbcClick = function () {
                    //     //                     invisibleFormService.submit(GetSbcFormConfig);
                    //     //                     //btsGlobal.coremetrics.elementTag('Download Summary of Benefits','MEMBER: YOUR COVERAGE');
                    //     //                 };
                    //     //             }
                    //     //         }
                    //     //     }
                    //     // }
                    //     $scope.xhrRequestFinished('SBC');
                    //     // deferred.resolve();
                    // }, function (sbcError) {
                    //     $scope.xhrRequestFinished('SBC');
                    // });
                }
                ; //medical product is absent


                $scope.xhrRequestFinished('UMS');
            });

        }
    ]);
};

//};
